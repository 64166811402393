@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "inter";

  src: url("../src/asstes/fonts/Inter-Medium.otf") format("otf");
}
@font-face {
  font-family: "Roboto";

  src: url("../src/asstes/fonts/Roboto-Medium.ttf") format("ttf");
}
@font-face {
  font-family: "Montserrat";

  src: url("../src/asstes/fonts/Montserrat-VariableFont_wght.ttf") format("ttf");
}

.logoHeader {
  display: flex;
  position: relative;
  object-position: center;
  content: normal;
}

.headerLineHeight {
  line-height: 4.75rem;
}

.loginLink {
  src: url("../src/asstes/fonts/Montserrat-VariableFont_wght.ttf") format("ttf");
  text-decoration: none;
  font-family: Inter, sans-serif;
  font-weight: 800;
  font-size: 2rem;
}
